import { DigitalIdApi } from './DigitalIdApi'
import { VaccineApi } from './VaccineApi'
import { UploadApi } from './UploadApi'
import { LookupApi } from './LookupApi'
import { TextApi } from './TextApi'
import { OLDVaccineApi } from './OLDVaccineApi'

export const apiReducers = {
  [DigitalIdApi.reducerPath]: DigitalIdApi.reducer,
  [VaccineApi.reducerPath]: VaccineApi.reducer,
  [UploadApi.reducerPath]: UploadApi.reducer,
  [LookupApi.reducerPath]: LookupApi.reducer,
  [TextApi.reducerPath]: TextApi.reducer,
  [OLDVaccineApi.reducerPath]: OLDVaccineApi.reducer
}

export const apiMiddleware = [DigitalIdApi.middleware, VaccineApi.middleware, UploadApi.middleware, LookupApi.middleware, TextApi.middleware, OLDVaccineApi.middleware]
