import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ActiveDrawer =
  | 'None'
  | 'CovidDetails'
  | 'FluDetails'
  | 'CovidUpload'
  | 'Documents'
  | 'FluUpload'
  | 'ImageUpload'
  | 'ScanQR'
  | 'CheckIn'
  | 'Exemptions'
  | 'ExemptionsUpload'

export interface DrawerState {
  drawer: ActiveDrawer
}

const initialState: DrawerState = {
  drawer: 'None'
}

// https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-state-slice
export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawer: (state, action: PayloadAction<ActiveDrawer>) => {
      state.drawer = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setDrawer } = drawerSlice.actions

export default drawerSlice.reducer
