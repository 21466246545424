import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { appInitialized } from '@mit/aws-react'
import { useGetProfileQuery } from 'api/DigitalIdApi'
import { useGetAppTextQuery } from 'api/TextApi'
import { setAppText, setPerson } from 'store/slice/GlobalSlice'
import { useGetPersonQuery } from 'api/OLDVaccineApi'

interface UseAppInitializationReturn {
  initializeApp: () => void
}

export const useAppInitializer = (): UseAppInitializationReturn => {
  const [initializeApi, setInitializeApi] = useState(false)
  const dispatch = useDispatch()
  /**
   * Use Skip Option to not query api on initial load. Once state flag is flipped to true, queries will load.
   * Use useEffect to trigger when everything you need has loaded and any auth check has passed/not passed
   *
   * Below is just an example. The profile component handles fetching it's own data
   */
  const { data: profile } = useGetProfileQuery(undefined, { skip: !initializeApi })
  const { data: appText } = useGetAppTextQuery(undefined, { skip: !initializeApi })
  const { data: person } = useGetPersonQuery(undefined, { skip: !initializeApi })

  useEffect(() => {
    if (profile?.krb_name != null && appText !== undefined && person !== undefined) {
      dispatch(setAppText(appText))
      dispatch(setPerson(person))
      appInitialized({
        isLoading: false,
        hasAccess: true,
        hasError: false
      })
    }
  }, [profile, appText, person, dispatch])

  const initializeApp = async (): Promise<void> => {
    setInitializeApi(true)
  }

  return { initializeApp }
}
