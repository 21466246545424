import { getStage, Region, RegionalConfig } from '@mit/aws-react'
import appConfig from '../app-config'

type EnvReturn = 'dev' | 'staging' | 'production'
type envStages = 'develop' | 'release' | 'master'

export const getEnv = (): EnvReturn => {
  switch (getStage(appConfig)) {
    case 'develop':
      return 'dev'
    case 'release':
      return 'staging'
    case 'master':
      return 'production'
  }
  return 'production'
}

export const getEnvStage = (): envStages => {
  return getStage(appConfig)
}

export const getActiveRegion = (): RegionalConfig => {
  const region = new Region(appConfig)

  return region.getActiveRegionConfig()
}
