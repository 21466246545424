import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppTexts } from 'api/TextApi'
import { VaccinationPost } from 'api/VaccineApi'

export interface Exemption {
  type: number
  heading: string
  required: boolean
}

export type Status = 'Covid' | 'Flu' | 'None'

export interface GlobalState {
  appText: AppTexts
  exemption: Exemption
  exemptionId: string
  selectedStatus: Status
  person: any
  vaccinations: VaccinationPost[]
}

const initialState: GlobalState = {
  appText: {},
  exemption: {
    type: 0,
    heading: 'Exemption',
    required: false
  },
  exemptionId: '',
  selectedStatus: 'None',
  person: undefined,
  vaccinations: []
}

// https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-state-slice
export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setAppText: (state, action: PayloadAction<AppTexts>) => {
      state.appText = action.payload
    },
    setExemptionType: (state, action: PayloadAction<Exemption>) => {
      state.exemption = action.payload
    },
    setExemptionId: (state, action: PayloadAction<string>) => {
      state.exemptionId = action.payload
    },
    setSelectedStatus: (state, action: PayloadAction<Status>) => {
      state.selectedStatus = action.payload
    },
    setPerson: (state, action: PayloadAction<any>) => {
      state.person = action.payload
    },
    setVaccinations: (state, action: PayloadAction<VaccinationPost[]>) => {
      state.vaccinations = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAppText, setExemptionType, setExemptionId, setSelectedStatus, setPerson, setVaccinations } = globalSlice.actions

export default globalSlice.reducer
